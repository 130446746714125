
/*--
    05. Hero Css
--------------------------------*/
.hero-slider-1 {
    & .single-slide {
        height: 950px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        @media #{$laptop-device}{
            height: 750px
        }
        @media #{$desktop-device}{
            height: 700px
        }
        @media #{$tablet-device}{
            height: 580px
        }
        @media #{$large-mobile}{
            height: 880px;
        }
        @media #{$small-mobile}{
            height: 880px;
        }
        & .slider-text-info {
            margin-top: 150px;
            @media #{$tablet-device}{
                margin-top: 50px;
            }
            @media #{$large-mobile}{
                margin-top: 50px;
            }
            & h4 {
                color: #333;
                font-size: 28px;
                position: relative;
                display: inline-block;
                margin-bottom: 20px;
                font-family: $lato;
                &::after {
                    position: absolute;
                    content: "";
                    width: 100px;
                    height: 1px;
                    background: $theme-color;
                    right: -120px;
                    bottom: 0;
                }
                @media #{$large-mobile,$tablet-device}{
                    font-size: 22px;
                    &::after {
                        width: 60px;
                        right: -80px;
                    }
                }
            }
            & h1 {
                color: $theme-color;
                font-size: 52px;
                font-family: $lato;
                font-weight: bold;
                margin-bottom: 20px;
                @media #{$desktop-device}{
                    font-size: 40px;
                }
                @media #{$tablet-device}{
                    font-size: 32px;
                }
                @media #{$large-mobile}{
                    font-size: 26px;
                }
            }
            & p {
                width: 80%;
                @media #{$large-mobile}{
                    width: 95%;
                }
            }
            & .slider-button {
                margin-top: 50px;
                & .slider-btn {
                    font-size: 15px;
                    color: #333;
                    position: relative;
                    font-weight: 600;
                    &::after {
                        position: absolute;
                        content: "";
                        width: 1px;
                        height: 30px;
                        background: $theme-color;
                        left: 0;
                        bottom: 30px;
                        @media #{$tablet-device}{
                            height: 15px;
                        }
                    }
                    &:hover {
                        color: $theme-color;
                    }
                }
                @media #{$tablet-device}{
                    margin-top: 30px;
                }
            }
        }
        & .slider-inner-image {
            text-align: right;
            @media #{$laptop-device}{
                text-align: center;
                & img {
                    max-width: 400px;
                }
            }
            @media #{$desktop-device}{
                margin-top: 60px;
            }
            @media #{$large-mobile}{
                & img {
                    max-width: 300px;
                }
                text-align: center;
                margin-top: 50px;
            }
            @media #{$small-mobile}{
                & img {
                    max-width: 250px;
                }
                text-align: center;
                margin-top: 50px;
            }
            @media #{$tablet-device}{
                margin-top: 40px;
            }
        }
        
    }
    
}
.slider-text-info {
    & h4 {
      animation-name: fadeInTop;
      animation-duration: 0.9s;
    }
    & h1 {
      animation-name: fadeInLeft;
      animation-duration: 0.9s;
    } 
}
.slider-inner-image {
    animation-name: fadeInRight;
    animation-duration: 1.9s;
}
.home-overlay-img-1 {
    position: absolute;
    top: 100px;
    left: 0;
}
.home-overlay-img-1 {
    animation: movebounce  2s linear infinite; 
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px); 
    }
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0px); 
    }
    50% {
        transform: translateX(20px); 
    }
    100% {
        transform: translateX(0px); 
    }
}
@-webkit-keyframes fadeInRight {
    0% {
       opacity: 0;
       -webkit-transform: translateX(20px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
}
@keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
}
.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
















