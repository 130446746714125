/*--
    16. Error 404 Css
---------------------------*/
.bg_image-errer {
    background: $white;
}
.section-not-found {
    overflow: hidden;
}
.not-found-default {
    overflow: hidden;
    .content {
        position: relative;
        z-index: 2;
        & h2 {
            color: #333;
            font-size: 30px;
            margin-top: 35px;
            margin-bottom: 43px;
            font-family: $lato;
            @media #{$large-mobile} {
                font-size: 24px;
            }
        }
        & a {
            &.page-back-btn {
                background: $theme-color;
                height: 60px;
                display: inline-block;
                padding: 0 40px;
                line-height: 60px;
                border-radius: 30px;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                transition: 0.4 ease-in-out;
                &:hover {
                    background: $theme-color;
                    border-radius: 20px 0;
                    color: #fff;
                    transform: translateY(-5px);
                }
                i {
                    padding-right: 12px;
                    font-size: 18px;
                }
            }

        }
    }
}

.cloud {
    width: 350px;
    height: 120px;
    background: #f1f1f1;
    background: linear-gradient(top, #f1f1f1 100%);
    background: -webkit-linear-gradient(top, #f1f1f1 100%);
    background: -moz-linear-gradient(top, #f1f1f1 100%);
    background: -ms-linear-gradient(top, #f1f1f1 100%);
    background: -o-linear-gradient(top, #f1f1f1 100%);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    position: absolute;
    margin: 120px auto 20px;
    transition: ease 1s;
}

.cloud:after,
.cloud:before {
    content: '';
    position: absolute;
    background: #f1f1f1;
    z-index: -1
}

.cloud:after {
    width: 100px;
    height: 100px;
    top: -50px;
    left: 50px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
}

.cloud:before {
    width: 180px;
    height: 180px;
    top: -90px;
    right: 50px;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
}

.cloud-1 {
    top: -198px;
    left: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0.9;
    -webkit-animation: moveclouds 15s linear infinite;
    -moz-animation: moveclouds 15s linear infinite;
    -o-animation: moveclouds 15s linear infinite;
}

.cloud-2 {
    top: -122px;
    left: 140px;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-animation: moveclouds 17s linear infinite;
    -moz-animation: moveclouds 17s linear infinite;
    -o-animation: moveclouds 17s linear infinite;
}


.cloud-3 {
    left: 409px;
    top: -50px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.6;
    -webkit-animation: moveclouds 25s linear infinite;
    -moz-animation: moveclouds 25s linear infinite;
    -o-animation: moveclouds 25s linear infinite;
}

.cloud-4 {
    left: -46px;
    bottom: 135px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.8;

    -webkit-animation: moveclouds 25s linear infinite;
    -moz-animation: moveclouds 25s linear infinite;
    -o-animation: moveclouds 25s linear infinite;
}

.cloud-5 {
    left: 469px;
    bottom: -57px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.75;
    -webkit-animation: moveclouds 18s linear infinite;
    -moz-animation: moveclouds 18s linear infinite;
    -o-animation: moveclouds 18s linear infinite;
}

.cloud-6 {
    left: 100%;
    top: 263px;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.8;
    -webkit-animation: moveclouds 20s linear infinite;
    -moz-animation: moveclouds 20s linear infinite;
    -o-animation: moveclouds 20s linear infinite;
}

@-webkit-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}

@-moz-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}

@-o-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}