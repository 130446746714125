/*--
    09. Pricing Table Css
---------------------------------*/
.pricing-container {
    justify-content: center;
}

.single-pricing {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: calc(25% - 30px); // Fixe la largeur des éléments .single-pricing
    box-sizing: border-box;
    margin-bottom: 30px; // Ajoute un espace entre les éléments .single-pricing
    @media #{$large-mobile}{
        margin-right: 0;
    }
    @media (min-width: 1024px) {
        &:not(:last-child) {
            margin-right: 0;
        }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        &:not(:nth-child(2n)) {
            margin-right: 0;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
        & .pricing-title{
            min-height: 150px;
        }
    }
    @include transition(0.3s); 
    & .pricing-header {
        padding: 55px 50px 25px;
        flex-shrink: 0;
        @media (min-width: 480px) and (max-width: 767px){
            padding: 55px 80px 25px;
        }
        & h4 {
            font-size: 2em;
            font-weight: 600;
            font-family: $lato;
        } 
    }
    & .pricing-title {
        padding: 30px 60px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        flex-shrink: 0;
        text-align: center;
        min-height: 150px;
        @media (max-width: 1199px){
            min-height: 170px;
        }
        @media (max-width: 1399px){
            padding: 30px;
        } 
        @include transition(0.4s);
        background: $white;
        & h3 {
            font-size: 18px;
            font-family: $lato;
            font-weight: 600;
            & span {
                font-size: 24px;
            }
            & .price-tb {
                font-weight: 700;
                font-size: 36px;
            }
        }
    }
    & .pricing-body {
        padding: 0 30px 30px;
        flex-grow: 1;
        text-align: center;
        min-height: 200px;
        @media (min-width: 1200px) and (max-width: 1399px){
            min-height: 250px;
        }
        & ul {
            margin-top: 30px;
            & li {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    & .pricing-footer {
        flex-shrink: 0;
        margin-top: auto;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        & a {
            border: 1px solid $theme-color;
            padding: 10px 25px;
            color: $theme-color;
            font-size: 16px;
            margin: auto;
            &:hover {
                color: $white;
                background: $theme-color;
            }
        }
    }
    &.active {
        position: relative;
        border: 2px solid #FEBD4A;
        & .pricing-header {
            & h4 {
                color: $theme-color;
            } 
        }
        & .pricing-title {
            background: #FEBD4A;
            & h3 {
                color: $white;
            }
            & p {
                color: $white;   
            }
        }
        & .pricing-footer {
            & a {
                border: 1px solid #FEBD4A;
                color: $white;
                background: #FEBD4A;
                &:hover {
                    color: $white;
                    background: $theme-color;
                    border: 1px solid $theme-color;
                }
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: -5px;
            right: -5px;
            width: 100px; /* Modifier la largeur de l'image selon vos besoins */
            height: 100px; /* Modifier la hauteur de l'image selon vos besoins */
            background-image: url(../images/icon/best-seller.png);
            background-size: cover;
            background-repeat: no-repeat;
        }
        &:hover {
            border: 2px solid #4e4d97;
            & .pricing-footer {
                & a {
                border: 1px solid $theme-color;
                color: $theme-color;
                background: $white;
                &:hover {
                    color: $white;
                    background: $theme-color;
                    border: 1px solid $theme-color;
                }
            }
            }
        }
    }
    &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        & .pricing-title {
            background: $theme-color;
            & h3 {
                color: $white;
            }
            & p {
                color: $white;   
            }
        }
    }
}

/*--
    10. Testimonial Css
---------------------------------*/

.testimonial-wrap {
    padding: 0 100px;
    @media #{$large-mobile}{
        padding: 0;
    }
    & .testimonial-image {
        text-align: center;
        margin-bottom: 20px;
        & img {
            border-radius: 50%;
            margin: auto;
        }
    }
    & .testimonial-content {
        padding: 30px 100px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        margin: 0 5px;
        & ul {
            margin-bottom: 10px;
            & li {
                display: inline-block;
            }
        }
        @media #{$desktop-device}{
            padding: 30px 50px;
        }
        @media #{$large-mobile,$tablet-device}{
            padding: 30px;
        }
    }
    & .author-info {
        margin-top: 22px;
        & h4 {
            font-family: $lato;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}
.testimonial-active {
    & .slick-arrow {
        font-size: 36px;
        position: absolute;
        top: 60%;
        left: 0;
        right: auto;
        border: none;
        border-radius: 5px;
        z-index: 5;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            color: $theme-color;
        }
        &.slick-next {
            right: 0;
            left: auto;
        }
    } 
    &:hover .slick-arrow {
        transform: translateY(-60%);
        visibility: visible;
        opacity: 1;
    }
}
