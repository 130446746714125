@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Open+Sans:300,400,600,700,800");
@import '~bootstrap';
@import "~bootstrap-icons";
@import "../components/seomar/assets/css/plugins.css";
@import "../components/seomar/assets/scss/style.scss";
@import "wowjs/css/libs/animate";
@import "~tarteaucitronjs/css/tarteaucitron.css";

.logo img{
    height: 32px;
}

.pricing-header{
    background: url('../images/services/netcom-marketing-serices-package.png') no-repeat; 
    background-size: cover;
}

#scrollUp {
    width: 40px;
    height: 40px;
    color: #ffffff;
    right: 20px;
    bottom: 60px;
    line-height: 40px;
    text-align: center;
    overflow: hidden;
    background: #4e4d97 url(../images/netcom-marketing-up.png) center no-repeat;
}

.breadcrumb-area {
    background: url(../images/netcom-marketing-breadcrumb.jpg) repeat scroll 0 0 !important;
    text-align: center;
    color: white;
}

.footer-bg {
    background: url(../images/netcom-marketing-footer.jpg);
    background-size: cover;
    background-position: center;
}

.footer-area .footer-top {
    & .container {
        display: flex;
        justify-content: space-between;

        & .left {
            order: 1;
            flex: 1;
            @media (min-width: 768px) and (max-width: 1024px) {
                flex: none;
                width: 30%;
            }
        }
        & .center {
            order: 2;
            flex: 1;
            text-align: center;
            @media (min-width: 768px) and (max-width: 1024px) {
                flex: none;
                width: 30%;
            }
        }
        & .right {
            order: 3;
            flex: 1;
            text-align: right;
            @media (min-width: 768px) and (max-width: 1024px) {
                flex: none;
                width: 30%;
            }
        }
        @media #{$large-mobile} {
            flex-direction: column;
            & .left, & .center, & .right {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.form-newsletter{
    border-radius: 0;
    height: 35px;
    padding: 10px;
    color: #555;
    font-size: 14px;
    border: 1px solid #4e4d97;
}

.google-map{
    height: 400px;
}

.post-info .blockquote-inner .citation{
    margin: 0; 
}

.post-info .blockquote-inner .signature{
    font-weight: bold;
    text-align: right;
}

.feature-area-inner-four .single-feature .feature-text p {
    text-align: justify;
}