/*--  
    14. Case Area
---------------------------------------*/
.single-case-item {
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
    @include transition(0.3s);
    & .case-image {
        display: block;
        position: relative;
        overflow: hidden;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: rgba($theme-color, 0.9);
            background-image: url("../images/icon/plus.png");
            background-position: center center;
            background-repeat: no-repeat;
            @include transform(scale(1.5));
            @include transition(0.3s);
            opacity: 0;
        }
        & img {
            width: 100%;
        }
    }
    &:hover {
        box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.2);
        & .case-image::before {
            @include transform(scale(1));
            opacity: 1;
        }
    }
    & .case-content {
        padding: 27px 27px;
        text-align: center;
        & h3 {
            font-size: 18px;
            font-weight: 600;
            display: block;
            margin-bottom: 8px;
            font-family: $lato;
        }
        & p {
            font-size: 15px;
        }
    }
}
/*--
   - Project Details Css
------------------------------------*/

.project-title {
    & h2 {
        font-family: $lato;
        font-size: 30px;
        margin-bottom: 15px;
        font-weight: 600;
        @media #{$large-mobile} {
            font-size: 24px;
        }
    }
}
.project-info {
    & ul {
        & li {
            margin-bottom: 10px;
            font-weight: 600;
            font-family: $lato;
            &:last-child {
                margin-bottom: 0;
            }
            & span{
                font-family: $opensans;
               font-weight: 500;
                margin-left: 5px;
            }
        }
    }
    @media #{$large-mobile , $tablet-device} {
        margin-top: 30px;
    }
}