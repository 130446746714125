/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 09-avr.-2021, 10:55:39
    Author     : olivi
*/

/* 
===================
    User Profile
===================
*/

.user-profile .widget .edit-profile {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #4361ee;
    background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 69, 255, 0.2); }
.user-profile .widget .edit-profile svg {
    font-size: 17px;
    vertical-align: middle;
    margin-right: 0;
    color: #fff;
    width: 19px;
    align-self: center; }
.user-profile .widget .user-info {
    margin-top: 40px; }
.user-profile .widget .user-info img {
    border-radius: 9px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }
.user-profile .widget .user-info p {
    font-size: 20px;
    font-weight: 600;
    margin-top: 22px;
    color: #4361ee; }
.user-profile .widget .user-info-list ul.contacts-block {
    border: none;
    max-width: 300px;
    margin: 36px auto; }
.user-profile .widget .user-info-list ul.contacts-block li {
    margin-bottom: 13px;
    font-weight: 600;
    font-size: 13px; }
.user-profile .widget .user-info-list ul.contacts-block li a {
    font-weight: 600;
    font-size: 15px;
    color: #4361ee; }
.user-profile .widget .user-info-list ul.contacts-block svg {
    width: 21px;
    margin-right: 15px;
    color: #888ea8;
    vertical-align: middle;
    fill: rgba(0, 23, 55, 0.08); }
.user-profile .widget .user-info-list ul.contacts-block li:hover svg {
    color: #4361ee;
    fill: rgba(27, 85, 226, 0.239216); }
.user-profile .widget .user-info-list ul.contacts-block ul.list-inline {
    margin: 27px auto; }
.user-profile .widget .user-info-list ul.contacts-block ul.list-inline div.social-icon {
    border: 2px solid #e0e6ed;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-self: center; }
.user-profile .widget .user-info-list ul.contacts-block ul.list-inline svg {
    margin-right: 0;
    color: #4361ee;
    width: 19px;
    align-self: center; }

.list-group-item {
    border: 1px solid #e0e6ed;
    padding: 10px 12px; }

a.list-group-item.list-group-item-action.active i {
    color: #010156; }

.list-group-item-action:hover {
    color: #3b3f5c;
    background-color: #f1f2f3;
    box-shadow: 0px 0px 12px 1px rgba(113, 106, 202, 0.08); }

.list-group.task-list-group .list-group-item-action.active {
    background-color: #888ea8;
    color: #fff; }
.list-group.task-list-group .list-group-item-action.active .new-control.new-checkbox {
    color: #fff;
    font-size: 14px; }

