/*-----------------------------------------------------------------------------------

    Template Name: Seomar - SEO Digital & Marketing Bootstrap 4 Template
    Description: SEO Digital & Marketing Bootstrap 4 Template.
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    =========================================
	
    01. Theme default CSS
        - Common Classes
        - Section Padding
    02. Element
        - Button Style 
        - Section Title Css
        - Breadcrumb Style
        - Paginatoin Css
    03. Mobile Menu Css
    04. Header css
    05. Hero Css
    06. About Css 
    07. Service Css
    08. Video Css
    09. Pricing Table Css
    10. Testimonial Css
    11. Blog Css
        - Blog Details Css
    12. Team Css
    13. Footer Css
    14. Case Area
        - Project Details Css
    15. Contact Page Css
    16. Error 404 Css
    
    
-----------------------------------------------------------------------------------*/

@import 'variabls';
@import 'mixin';
@import 'common';
@import 'element';
@import 'navigation';
@import 'header';
@import 'slider';
@import 'about-us';
@import 'service';
@import 'video';
@import 'pricing-table';
@import 'blog';
@import 'team';
@import 'footer';
@import 'case';
@import 'contact';
@import 'error-404';




