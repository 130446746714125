
/*--
    - Paginatoin Css
---------------------------------*/

.paginatoin-area {
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    padding: 15px 0;
    margin-top: 30px;
    & p {
        @media #{$small-mobile , $large-mobile} {
            text-align: center;
            margin-bottom: 8px;
        }
    }
}

.pagination-box {
    display: flex;
    justify-content: center;
    & li {
        display: inline-block;
        &.active a {
            color: $theme-color;
        }
        & a {
            color: #333333;
            display: block;
            padding: 0 12px;
            font-size: 15px;
            &:hover {
                color: $theme-color;
            }
            & span {
                font-size: 18px;
            }
        }
    }
}
