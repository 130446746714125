/* Style for the container that holds all the checkboxes */
.horizontal-checkboxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Ensure individual checkboxes do not grow or shrink */
.horizontal-checkboxes .n-chk {
    flex: 0 0 auto;
    padding-top: 12px;
    padding-left: 15px;
}

.new-control.new-checkbox {
    cursor: pointer;
}

.new-control.new-checkbox .new-control-indicator {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 17px;
    height: 17px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #e0e6ed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 4px;
}

.new-control.new-checkbox > input:checked ~ span.new-control-indicator {
    background: #888ea8;
}

.new-control.new-checkbox > input:checked ~ span.new-control-indicator:after {
    display: block;
}

.new-control.new-checkbox span.new-control-indicator:after {
    border: solid #fff;
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -5px;
    width: 4px;
    height: 8px;
    border-width: 0 2px 2px 0 !important;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
    margin-left: -2px;
    margin-top: -5px;
    width: 4px;
    height: 8px;
    height: 8px;
}

.new-checkbox-rounded span.new-control-indicator {
  border-radius: 50% !important; }

.new-control.new-checkbox[class*="checkbox-outline-"] > input:checked ~ span.new-control-indicator {
  background-color: transparent; }

.new-control.new-checkbox.checkbox-outline-secondary > input:checked ~ span.new-control-indicator {
  border: 2px solid #805dca; }

.new-control.new-checkbox.checkbox-outline-secondary > input:checked ~ span.new-control-indicator:after {
  border-color: #805dca; }

.w-title {
    align-self: center;
    h5 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: 0px;
        display: block;
        color: #0e1726;
    }
    span {
        font-size: 12px;
        font-weight: 500;
    }
}