/*--
    12. Team Css
----------------------------*/

.single-team {
    border: 1px solid #ddd;
    box-shadow: 0px 0px 5px 5px rgba(15, 9, 128, 0.035), 5px 12px 30px 5px rgba(15, 9, 128, 0.05);
    & .team-imgae {
        position: relative;
        & img {
            width: 100%;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(78, 77, 151, 0.8);
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: all 0.4s ease-in-out;
        }
        & .social-link {
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            color: #fff;
            transition: all 0.3s ease-in-out;
            width: 100%;
            opacity: 0;
            & a {
                font-size: 34px;
                margin: 0 5px;
                &:hover {
                    color: #333;
                }
            }
        }
    }
    & .team-info {
        background: #fff;
        text-align: center;
        padding: 10px 0;
        & h3 {
            font-weight: 700;
            font-size: 16px;
            margin: 0  0 5px 0;
            font-family: $lato;
            
        }
        & p {
            font-size: 14px;
            margin-bottom: 4px;
            line-height: 20px;
            text-transform: capitalize;
        }
    }
    &:hover {
        & .team-imgae {
            &::before {
                opacity: 1;
            }
        }
        & .social-link {
            bottom: 20px;
            opacity: 1;
        }
        
    }
}