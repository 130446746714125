/*--
    04. Header Css
--------------------------------------*/
.inner-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
}
.logo {
    margin-top: 30px;
    @media #{$large-mobile,$tablet-device}{
        margin: 25px 0;
    }  
}
.main-menu {
    display: flex;
    justify-content: flex-end;
    & ul {
        & li {
            display: inline-block;
            margin-right: 58px;
            position: relative;
            &:last-child {
                margin-right: 0;
            }
            &.active{
                & a {
                    color: $theme-color; 
                }
            }
            & a {
                color: #333;
                font-size: 14px;
                font-weight: 500;
                padding: 30px 0;
                font-family: $opensans;
                &:hover {
                    color: $theme-color;
                }
            }
            // sub-menu Css
            & .sub-menu {
                width: 200px;
                box-shadow:  0 0 5px rgba(0, 0, 0, 0.2);
                position: absolute;
                background: #fff;
                padding: 15px;
                z-index: 9;
                left: 0;
                top: 120%;
                visibility: hidden;
                @include opacity(0);
                @include transition(.3s);
                border-bottom: 2px solid $theme-color;
                @media #{$laptop-device}{
                    left: -25px;
                }
                @media #{$desktop-device}{
                    left: -25px;
                }
                & li {
                    display: block;
                    margin-right: 0;
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    & > a {
                        display: block;
                        padding: 0;
                        font-weight: 500;
                        text-align: left;
                        text-transform: capitalize;
                        color: #333;
                        font-size: 14px;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
            &:hover {
                color: $theme-color;
                & .sub-menu{
                    visibility: visible;
                    @include opacity(1);
                    top: 100%;
                }
            }
        }
    }
}
.is-sticky {
    & .inner-header {
        position: absolute;
        top: 0;
        width: 100%;
        background: #ffffff;
        z-index: 999;
        position: fixed;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        & .logo {
            margin: 20px 0;
        }
        & ul {
            & li {
                & a {
                    padding: 25px 0;
                }
                // sub-menu Css
                & .sub-menu {
                    & li {
                        & > a {
                            padding: 0;
                        }
                    }
                }
            }
        }
        .meanmenu-reveal {
            @media #{$large-mobile}{
                top: -45px !important;
            }
        }
    }
}
.is-sticky {
    position: absolute;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 999;
    position: fixed;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    & .logo {
        margin-top: 20px;
        @media #{$large-mobile,$tablet-device}{
            margin: 15px 0;
        }
    }
    & ul {
        & li {
            & a {
                padding: 25px 0;
            }
            // sub-menu Css
            & .sub-menu {
                & li {
                    & > a {
                        padding: 0;
                    }
                }
            }
        }
    }
    .meanmenu-reveal {
        top: -39px !important;
        @media #{$large-mobile}{
            top: -39px !important;
        }
    }
}
