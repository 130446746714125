// Font Family
$opensans: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif;



// Colors ---------------

$white: #ffffff;
$black: #000000;

// Light Color
$light: #191919;
$light-grey: #f6fafb;

// Dark Color
$dark: #333;
$dark-grey: #222222;

// Body Color
$body-color: #fff;
$body-color-light: #909090;


// Body Color
$grey-light: #f0f0f0;
$grey-dark: #e4e4e4;

// Heading Color
$heading-color: #333333;
$heading-color-light: #505050;

// Theme Color
$theme-color: #4e4d97; 
$theme-color-two: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%); 

// Responsive Variables
$extraBig-device : 'only screen and (min-w idth: 1600px)';
$laptop-device : 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$desktop-device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tablet-device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large-mobile:'only screen and (max-width: 767px)';
$small-mobile: 'only screen and (max-width: 479px)';

