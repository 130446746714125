.custom-file-container {
    box-sizing: border-box;
    position: relative;
    display: block
}

.custom-file-container * {
    box-sizing: border-box
}

.custom-file-container .label-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px
}

.custom-file-container .clear-button {
    color: #333;
    font-size: 26px;
    height: 26px;
    line-height: 26px;
    text-decoration: none;
    transition: color .2s ease-in-out
}

.custom-file-container .clear-button:hover {
    color: #777
}

.custom-file-container .input-container {
    display: inline-block;
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    width: 100%
}

.custom-file-container .input-container:hover {
    cursor: pointer
}

.custom-file-container .input-hidden {
    height: 40px;
    margin: 0;
    max-width: 100%;
    min-width: 300px;
    opacity: 0
}

.custom-file-container .input-visible {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #c0c0af;
    color: #333;
    height: 40px;
    left: 0;
    line-height: 1.5;
    overflow: hidden;
    padding: 8px 12px;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    z-index: 5
}

.custom-file-container .browse-button {
    background-color: #edede8;
    border-left: 1px solid #c0c0af;
    color: #333;
    display: block;
    height: 38px;
    padding: 8px 12px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 6
}

.custom-file-container .image-preview {
    background-color: #edede8;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    height: 250px;
    overflow: auto;
    padding: 4px;
    transition: background .2s ease-in-out;
    width: 100%
}

.custom-file-container .image-preview-item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    box-shadow: 0 4px 10px #33333340;
    float: left;
    height: 90px;
    margin: 1.858736059%;
    position: relative;
    transition: background .2s ease-in-out,opacity .2s ease-in-out;
    width: 29.615861214%
}

.custom-file-container .image-preview-item.multi-item-clear-animation {
    opacity: 0
}

.custom-file-container .image-preview-item-clear {
    background: #edede8;
    border-radius: 50%;
    box-shadow: 0 4px 10px #33333340;
    height: 20px;
    left: -6px;
    margin-top: -6px;
    position: absolute;
    text-align: center;
    transition: background .2s ease-in-out,color .2s ease-in-out;
    width: 20px
}

.custom-file-container .image-preview-item-clear:hover {
    background: #e2e2da;
    cursor: pointer
}

.custom-file-container .image-preview-item-clear-icon {
    color: #333;
    display: block;
    margin-top: -2px
}
