/*--
    - Section Title Css
-------------------------------*/


.section-title {
    margin-bottom: 30px;
    text-align: center;
    & h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: -5px;
        text-transform: uppercase;
        letter-spacing: 1px;
        
    }
    & h3 {
        color: $theme-color;
        font-size: 36px;
        text-transform: capitalize;
        font-weight: 700;
        font-family: $lato;
        & span {
            background: -webkit-linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        @media #{$large-mobile}{
            font-size: 30px;
        }
    }
    @media #{$large-mobile}{
        margin-bottom: 20px;
    }
}

.section-title-two {
    margin-bottom: 30px;
    & h2 {
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 20px;
        margin-top: -5px;
        @media #{$large-mobile}{
            font-size: 28px;
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 35px;
            height: 1px;
            background: $theme-color-two;
        }
        & span {
            color: $theme-color;
        }
    }
    @media #{$large-mobile}{
        margin-bottom: 20px;
    }
}